<template>
    <div class="loader-fullscreen" :class="{'full-screen' : ext }">
        <div class="loader-container">
            <img src="/favicon.webp" alt="target" class="img-fluid target" />
            <h1 class="loading-text" v-if="!ext">Loading {{ path }}</h1>
            <h1 class="loading-text" v-if="ext">Redirecting to eh01.groupbuy.pk...</h1>
        </div>
        <ul class="circles">
            <li></li><li></li><li></li><li></li><li></li>
            <li></li><li></li><li></li><li></li><li></li>
        </ul>
    </div>
</template>

<script>
    export default {
        props: ['path', 'ext'],
        
        mounted () {
            if (this.ext) {
                setTimeout(() => {
                    window.location.href = this.path
                    //window.open(this.path, '_blank')
                }, 4000)
            }
        },
    }

</script>

<style>
.loader-fullscreen{
    display: fablex;
    align-items: center;
    justify-content: center;
    background-color: #1d1d1d;
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 70px;
    z-index: 99;
}
.full-screen{
    z-index: 500;
    left: 0
}
@media only screen and (max-width: 790px) {
    .loader-fullscreen{
        left: 0;
    }
}

.loader-container {
    text-align: center;
}
.target {
    width: 62px;
}
.loading-text{
    color: #fff;
    font-weight: 500;
    margin: 10px;
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.2);
    animation: animate 25s linear infinite;
    bottom: -150px;
    
}

.circles li:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;
    animation-delay: 1s;
    animation-duration: 8s;
}

.circles li:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-delay: 0s;
    animation-duration: 10s;
}

.circles li:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-delay: 2s;
}

.circles li:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-delay: 2s;
}

.circles li:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-delay: 2s;
    animation-duration: 10s;
}

.circles li:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-delay: 2s;
    animation-duration: 8s;
}

.circles li:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-delay: 0s;
    animation-duration: 7s;
}



@keyframes animate {

    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }

}
</style>